import {FC, useState} from 'react'
import MaskedInput from 'react-input-mask'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty} from '../../../../../../_metronic/helpers'
import {Assessment} from '../core/_models'
import clsx from 'clsx'
import {toast} from 'react-toastify'
import {useListView} from '../core/ListViewProvider'
import {QuestionsListLoading} from '../components/loading/QuestionsListLoading'
import {createquestions, updatequestions} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import { useParams } from 'react-router-dom'


type Props = {
  isAssessmentLoading: boolean
  response: any
  status: any
}

const editquestionsSchema = Yup.object().shape({
  title: Yup.string()
    .min(1, 'Mínimo 3 caracteres')
    .max(1000, 'Maximum 1000 caracteres')
    .required('Título é obrigatório')
})

const QuestionsEditModalForm: FC<Props> = ({response, isAssessmentLoading}) => {
  const questions = response.length > 0 ? response[0] : response
  const { id } = useParams()
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()

  const [questionsForEdit] = useState<Assessment>({
    ...questions,
    assessment_id: Number(id || 0),
    status_id: questions.status_id,
    type_id: 1,
    answer_value: questions.answer_value
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: questionsForEdit,
    validationSchema: editquestionsSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      console.log(values)
      try {
        if (isNotEmpty(values.id)) {
          await updatequestions(values)
          toast.success(`Teste ${values.title} atualizado com sucesso`)
        } else {
          await createquestions(values)
          toast.success(`Teste ${values.title} criado com sucesso`)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y ps-1'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
        <div className='row mb-7 m-0 p-0'>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Título</label>
            <input
              type="text"
              placeholder='Nome do Teste'
              {...formik.getFieldProps('title')}              
              name='title'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.title && formik.errors.title},
                {
                  'is-valid': formik.touched.title && !formik.errors.title,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isAssessmentLoading}
            />
            {formik.touched.title && formik.errors.title && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.title}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Descrição</label>
            <input
              type="text"
              placeholder='Descrição do Teste'
              {...formik.getFieldProps('description')}              
              name='description'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.description && formik.errors.description},
                {
                  'is-valid': formik.touched.description && !formik.errors.description,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isAssessmentLoading}
            />
            {formik.touched.description && formik.errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.description}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Peso</label>
            <MaskedInput
              mask={"999"}
              maskChar={null}
              type="text"
              placeholder='Peso da pergunta'
              {...formik.getFieldProps('weight')}              
              name='weight'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.weight && formik.errors.weight},
                {
                  'is-valid': formik.touched.weight && !formik.errors.weight,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isAssessmentLoading}
            />
            {formik.touched.weight && formik.errors.weight && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.weight}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-sm-5 ps-0'>
              <label className='fw-bold fs-6 mb-2'>Opção Correta</label>
              <select
                className='form-control form-control-solid form-select mb-3 mb-lg-0'
                {...formik.getFieldProps('answer_value')}
                name='answer_value'
                disabled={formik.isSubmitting || isAssessmentLoading}
              >
                <option>
                  Selecione a opção
                </option>
               <option value={'sim'}>Sim</option>
               <option value={'não'}>Não</option>
              </select>
              {formik.touched.answer_value && formik.errors.answer_value && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.answer_value}</span>
                </div>
              )}
          </div>
          <div className='col-sm-5 ps-0'>
              <label className='fw-bold fs-6 mb-2'>Status</label>
              <select
                className='form-control form-control-solid form-select mb-3 mb-lg-0'
                {...formik.getFieldProps('status_id')}
                name='status_id'
                disabled={formik.isSubmitting || isAssessmentLoading}
              >
                <option>
                  Selecione o status
                </option>
               <option value={'30'}>Ativo</option>
               <option value={'31'}>Inativo</option>
              </select>
              {formik.touched.status_id && formik.errors.status_id && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.status_id}</span>
                </div>
              )}
          </div>
          <div className='col-sm-5 ps-0'>
              <label className='fw-bold fs-6 mb-2'>Tipo</label>
              <select
                className='form-control form-control-solid form-select mb-3 mb-lg-0'
                {...formik.getFieldProps('type_id')}
                name='type_id'
                disabled={formik.isSubmitting || isAssessmentLoading}
              >
                <option>
                  Selecione o tipo
                </option>
               <option value={'1'}>Sim/Não</option>
              </select>
              {formik.touched.type_id && formik.errors.type_id && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.type_id}</span>
                </div>
              )}
          </div>
        </div>
        </div>
        {/* end::Scroll */}
              
        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isAssessmentLoading}
          >
            Cancelar
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isAssessmentLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Confirmar</span>
            {(formik.isSubmitting || isAssessmentLoading) && (
              <span className='indicator-progress'>
                Por favor, aguarde...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isAssessmentLoading) && <QuestionsListLoading />}
    </>
  )
}

export {QuestionsEditModalForm}
