// @ts-nocheck
import {Column} from 'react-table'
import {StatusCell} from './StatusCell'
import {CustomHeader} from './CustomHeader'
import {Authors} from '../../core/_models'
import { GenericCell } from './GenericCell'
import { ActionsCell } from './ActionsCell'

const categoriesColumns: ReadonlyArray<Column<Authors>> = [
  // {
  //   Header: (props) => <UserSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Id' className='min-w-25px' />
    ),
    id: 'id',
    Cell: ({...props}) => <GenericCell labelName={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Nome' className='min-w-125px' />
    ),
    id: 'name',
    Cell: ({...props}) => <GenericCell labelName={props.data[props.row.index].name} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Status' className='min-w-90px' />
    ),
    id: 'status_name',
    Cell: ({...props}) => <StatusCell guardians={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='ID.Responsável' className='min-w-90px' />
    ),
    id: 'guardian_id',
    Cell: ({...props}) => <GenericCell labelName={props.data[props.row.index].guardian_id} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Responsável' className='min-w-90px' />
    ),
    id: 'guardian_name',
    Cell: ({...props}) => <GenericCell labelName={props.data[props.row.index].guardian_name} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <ActionsCell
        id={props.data[props.row.index].id}
      />
    ),
  }
]

export {categoriesColumns}
