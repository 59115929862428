import MaskedInput from 'react-input-mask'
import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {imageMeetingsPath, isNotEmpty} from '../../../../../../_metronic/helpers'
import {initialMeetings, Meetings} from '../core/_models'
import clsx from 'clsx'
import {toast} from 'react-toastify'
import {useListView} from '../core/ListViewProvider'
import {MeetingsListLoading} from '../components/loading/MeetingsListLoading'
import {createMeetings, updateMeetings, uploadImage} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'

type Props = {
  isMeetingsLoading: boolean
  response: any
  status: any
}

const editMeetingsSchema = Yup.object().shape({
  
})

const MeetingsEditModalForm: FC<Props> = ({response, isMeetingsLoading}) => {
  const meetings = response.length > 0 ? response[0] : response
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const blankImg = '/media/icons/duotune/general/gen046.svg'

  const [meetingsForEdit] = useState<Meetings>({
    ...meetings,
    type_id: meetings.type_id  || initialMeetings.type_id,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: meetingsForEdit,
    validationSchema: editMeetingsSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateMeetings(values)
          toast.success(`Reunião ${values.title} atualizada com sucesso`)
        } else {
          await createMeetings(values)
          toast.success(`Reunião ${values.title} criada com sucesso`)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  const changeImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return
    const newImage = await uploadImage(event.target.files![0])
    if (newImage?.data?.file_name) {
      formik.setFieldValue(event.target.name, newImage?.data?.file_name)
    }
  }
 
  const thumbImage = imageMeetingsPath('https://getinOxy-production.s3.amazonaws.com/meetings', formik.values.image_thumb)

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y ps-1'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Título da Reunião</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Nome que será exibido para os usuários'
              {...formik.getFieldProps('title')}
              type='text'
              name='title'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.title && formik.errors.title},
                {
                  'is-valid': formik.touched.title && !formik.errors.title,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isMeetingsLoading}
            />
            {formik.touched.title && formik.errors.title && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.title}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Descrição da Reunião</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Descrição que será exibida para os usuários'
              {...formik.getFieldProps('description')}
              type='text'
              name='description'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.description && formik.errors.description},
                {
                  'is-valid': formik.touched.description && !formik.errors.description,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isMeetingsLoading}
            />
            {formik.touched.description && formik.errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.description}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Dias para ficar visível antes do início </label>
            {/* end::Label */}

            {/* begin::Input */}
            <MaskedInput
              mask={"999"}
              maskChar={null}
              placeholder='Quantidade de dias que será exibido antes do início'
              {...formik.getFieldProps('days_before')}
              type='text'
              name='days_before'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.days_before && formik.errors.days_before},
                {
                  'is-valid': formik.touched.days_before && !formik.errors.days_before,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isMeetingsLoading}
            />
            {formik.touched.days_before && formik.errors.days_before && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.days_before}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Tempo que ficará visível após o início </label>
            {/* end::Label */}

            {/* begin::Input */}
            <MaskedInput
              mask={"999"}
              maskChar={null}
              placeholder='Quantidade de dias que será exibido após o início'
              {...formik.getFieldProps('days_after')}
              type='text'
              name='days_after'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.days_after && formik.errors.days_after},
                {
                  'is-valid': formik.touched.days_after && !formik.errors.days_after,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isMeetingsLoading}
            />
            {formik.touched.days_after && formik.errors.days_after && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.days_after}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>ID da reunião zoom ou meet  </label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Quantidade de dias que será exibido após o início'
              {...formik.getFieldProps('meeting_id')}
              type='text'
              name='meeting_id'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.meeting_id && formik.errors.meeting_id},
                {
                  'is-valid': formik.touched.meeting_id && !formik.errors.meeting_id,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isMeetingsLoading}
            />
            {formik.touched.meeting_id && formik.errors.meeting_id && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.meeting_id}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-2'>URL</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='URL da reunião'
              {...formik.getFieldProps('url')}
              type='text'
              name='url'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.url && formik.errors.url},
                {
                  'is-valid': formik.touched.url && !formik.errors.url,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isMeetingsLoading}
            />
            {formik.touched.url && formik.errors.url && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.url}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>

          <div className='row mb-7 m-0 p-0'>
            <div className='col-sm-5 ps-0'>
              <label className='fw-bold fs-6 mb-2'>Status</label>
              <select
                className='form-control form-control-solid form-select mb-3 mb-lg-0'
                {...formik.getFieldProps('status_id')}
                name='status_id'
                disabled={formik.isSubmitting || isMeetingsLoading}
              >
                <option disabled>
                  Selecione o status
                </option>
               <option value={'34'}>Ativo</option>
               <option value={'35'}>Inativo</option>
               <option value={'36'}>Cancelado</option>
              </select>
              {formik.touched.status_id && formik.errors.status_id && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.status_id}</span>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-7 m-0 p-0'>
            <div className='col-sm-5 ps-0'>
              <label className='fw-bold fs-6 mb-2'>Tipo</label>
              <select
                className='form-control form-control-solid form-select mb-3 mb-lg-0'
                {...formik.getFieldProps('type_id')}
                name='type_id'
                disabled={formik.isSubmitting || isMeetingsLoading}
              >
                <option disabled>
                  Selecione o tipo
                </option>
               <option value={'8'}>Zoom</option>
               <option value={'9'}>Google-Meet</option>
              </select>
              {formik.touched.type_id && formik.errors.type_id && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.type_id}</span>
                </div>
              )}
            </div>
          </div>
          
          <div className='row mb-7 m-0 p-0'>
            {/* begin::Input group Cover Image */}
            <div className='col-sm-6 ps-0'>
              {/* begin::Label */}
              <label className='d-block fw-bold fs-6 mb-5'>Banner da Reunião</label>
              {/* end::Label */}

              {/* begin::Image input */}
              <div
                className='image-input image-input-outline ps-5'
                data-kt-image-input='true'
                style={{backgroundImage: `url('${blankImg}')`}}
              >
                {/* begin::Preview existing image */}
                <div
                  className='image-input-wrapper w-250px h-125px'
                  style={{
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    backgroundImage: `url('${thumbImage}')`,
                  }}
                ></div>
                {/* end::Preview existing image */}

                {/* begin::Label */}
                <label
                  className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                  data-kt-image-input-action='change'
                  data-bs-toggle='tooltip'
                  title='Mudar imagem'
                >
                  <i className='bi bi-pencil-fill fs-7'></i>

                  <input
                    type='file'
                    name='image_thumb'
                    accept='.png, .jpg, .jpeg'
                    onChange={(event) => changeImage(event)}
                  />
                  <input type='hidden' name='image_thumb_hidden' />
                </label>
              </div>
              {/* begin::Hint */}
              <div className='form-text'>Tipos permitidos: png, jpg, jpeg.</div>
              {/* end::Hint */}
            </div>
          </div>

          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Data da Reunão</label>
            {/* end::Label */}

            {/* begin::Input */}
            <MaskedInput
              mask={"99/99/9999 99:99"}
              placeholder='Data da reunião'
              {...formik.getFieldProps('scheduled_date')}
              type='text'
              name='scheduled_date'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.scheduled_date && formik.errors.scheduled_date},
                {
                  'is-valid': formik.touched.scheduled_date && !formik.errors.scheduled_date,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isMeetingsLoading}
            />
            {formik.touched.scheduled_date && formik.errors.scheduled_date && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.scheduled_date}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
        </div>
        {/* end::Scroll */}
              
        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isMeetingsLoading}
          >
            Cancelar
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isMeetingsLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Confirmar</span>
            {(formik.isSubmitting || isMeetingsLoading) && (
              <span className='indicator-progress'>
                Por favor, aguarde...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isMeetingsLoading) && <MeetingsListLoading />}
    </>
  )
}

export {MeetingsEditModalForm}
