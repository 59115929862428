import {ID, Response} from '../../../../../../_metronic/helpers'

export const GuardianStatus = {
  'active': {id: 5, value: 'active', name: 'Ativo'},
  'inactive': {id: 6, value: 'inactive', name: 'Inativo'}
}

export type Guardians = {
  id?: ID
  name: string
  status_id: number 
  status_name: string
  phone_number: string
  birth_date?: Date
  zip_code: string
  address: string
  state_id: number
  state_name: string
  city_name: string
  district: string
  email: string
  children_name: string
  children_id: number
}

export type Status = {
  id?: ID
  name?: string
}

export type GuardiansQueryResponse = Response<Array<Guardians>>

export const initialAuthors: Guardians = {
  status_id: 0,
  status_name: '',
  state_id: 0,
  state_name: '',
  city_name: '',
  name: '',
  address: '',
  district: '',
  email: '',
  phone_number: '',
  zip_code: '',
  children_name: '',
  children_id: 0
}

export type ImageUploadResponse = Response<{file_name: string}>
