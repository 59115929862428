import {ID, Response} from '../../../../../../_metronic/helpers'

export const AssessmentType = {
  'Sim/Não': {id: 1, value: 'Sim/Não', name: 'Sim/Não'},
}

export const AssessmentStatus = {
  'active': {id: 23, value: 'active', name: 'Ativo'},
  'inactive': {id: 24, value: 'inactive', name: 'Inativo'}
}

export type Assessment = {
  id?: ID
  name: string;
  description: string;
  status_id: number;
  status_name: string;
  questions_number: number;
  time_to_finish: number;
  required_score: number;
  attempts_limit: number;
  attempt_interval: number;
  type_id: number;
}

export type AssessmentsQueryResponse = Response<Array<Assessment>>

export const initialAssessment: Assessment = {
  name: '',
  description: '',
  status_id: 0,
  type_id: 1,
  questions_number: 1,
  time_to_finish: 1,
  required_score: 1,
  attempt_interval: 1,
  attempts_limit: 1,
  status_name: ''
}

