import {useQuery} from 'react-query'
import {QuestionsEditModalForm} from './QuestionsEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getquestionsFilter} from '../core/_requests'
import { AssessmentType } from '../core/_models'
import { useParams } from 'react-router-dom'

const QuestionsEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const { id } = useParams()

  const {
    isLoading,
    data: questions,
    error,
    status
  } = useQuery(
    `${QUERIES.QUESTIONS_LIST}-questions-${itemIdForUpdate}`,
    () => {
      return getquestionsFilter(Number(id || 0), {id: itemIdForUpdate, take: 0, skip: 1})
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return (
      <QuestionsEditModalForm
      isAssessmentLoading={isLoading}
        response={{
          name: AssessmentType['Sim/Não'].id,
          created_at: new Date(),
        }}
        status={status}
      />
    )
  }

  if (!isLoading && !error && questions) {
    return (
      <QuestionsEditModalForm
      isAssessmentLoading={isLoading}
        response={questions?.data}
        status={status}
      />
    )
  }

  return null
}

export {QuestionsEditModalFormWrapper}
