import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../../_metronic/helpers'
import {
  Assessment,
  questionsQueryResponse,
} from './_models'

const API_URL = process.env.REACT_APP_API_URL

const getquestionsFilter = (id: number, query: any): Promise<questionsQueryResponse> => {
  console.log(id)
  return axios
    .get(`${API_URL}/admin/assessments-questions/filter/${id}`, {params: query})
    .then((d: AxiosResponse<questionsQueryResponse>) => d.data)
}

const createquestions = (caegory: Assessment): Promise<Assessment | undefined> => {
  return axios
    .post(`${API_URL}/admin/assessments-questions/create`, caegory)
    .then((response: AxiosResponse<Response<Assessment>>) => response.data)
    .then((response: Response<Assessment>) => response.data)
}

const updatequestions = (caegory: Assessment): Promise<Assessment | undefined> => {
  return axios
    .post(`${API_URL}/admin/assessments-questions/update/${caegory.id}`, caegory)
    .then((response: AxiosResponse<Response<Assessment>>) => response.data)
    .then((response: Response<Assessment>) => response.data)
}

export {
  getquestionsFilter,
  createquestions,
  updatequestions
}
