import {useListView} from '../../core/ListViewProvider'

const DocumentsListGrouping = () => {
  const {selected} = useListView()

  return (
    <div className='d-flex justify-content-end align-items-center'>
      <div className='fw-bolder me-5'>
        <span className='me-2'>{selected.length}</span> Selecionado(s)
      </div>

      <button
        type='button'
        className='btn btn-danger'
        onClick={async () => {}}
      >
        Deletar Selecionado(s)
      </button>
    </div>
  )
}

export {DocumentsListGrouping}
