// @ts-nocheck
import {Column} from 'react-table'
import {QuestionsInfoCell} from './QuestionsInfoCell'
import {QuestionsCustomHeader} from './QuestionsCustomHeader'
// import {UserSelectionHeader} from './UserSelectionHeader'
import {Assessment} from '../../core/_models'
import { QuestionsIdCell } from './QuestionsIdCell'
import { QuestionsActionsCell } from './QuestionsActionsCell'
import { QuestionsGenericCell } from './QuestionsGenericCell'
import { QuestionsStatusCell } from './QuestionsStatusCell'

const questionsColumns: ReadonlyArray<Column<Assessment>> = [
  // {
  //   Header: (props) => <UserSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => (
      <QuestionsCustomHeader tableProps={props} title='Id' className='min-w-25px' />
    ),
    id: 'id',
    Cell: ({...props}) => <QuestionsIdCell questions={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <QuestionsCustomHeader tableProps={props} title='Título' className='min-w-125px' />
    ),
    id: 'title',
    Cell: ({...props}) => <QuestionsInfoCell questions={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <QuestionsCustomHeader tableProps={props} title='Peso' className='min-w-20px' />
    ),
    id: 'weight',
    Cell: ({...props}) => <QuestionsGenericCell labelName={props.data[props.row.index].weight} />,
  },
  {
    Header: (props) => (
      <QuestionsCustomHeader tableProps={props} title='Status' className='min-w-90px' />
    ),
    id: 'status_name',
    Cell: ({...props}) => <QuestionsStatusCell category={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <QuestionsCustomHeader tableProps={props} title='Correta' className='min-w-20px' />
    ),
    id: 'answer_value',
    Cell: ({...props}) => <QuestionsGenericCell labelName={props.data[props.row.index].answer_value} />,
  },
  {
    Header: (props) => (
      <QuestionsCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <QuestionsActionsCell
        id={props.data[props.row.index].id}
      />
    ),
  },
]

export {questionsColumns}
