import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../../_metronic/helpers'
import {
  Guardians,
  GuardiansQueryResponse,
} from './_models'

const API_URL = process.env.REACT_APP_API_URL

const getGuardiansFilter = (query: any): Promise<GuardiansQueryResponse> => {
  return axios
    .get(`${API_URL}/admin/guardians/filter`, {params: query})
    .then((d: AxiosResponse<GuardiansQueryResponse>) => {
      return d.data;
    })
}

const updateGuardians = (meetings: Guardians): Promise<Guardians | undefined> => {
  return axios
    .post(`${API_URL}/admin/guardians/update/${meetings.id}`, meetings)
    .then((response: AxiosResponse<Response<Guardians>>) => response.data)
    .then((response: Response<Guardians>) => response.data)
}

export {
  updateGuardians,
  getGuardiansFilter
}
