import {ID, Response} from '../../../../../../_metronic/helpers'

export const AssessmentType = {
  'Sim/Não': {id: 1, value: 'Sim/Não', name: 'Sim/Não'},
}

export const Questionstatus = {
  'active': {id: 30, value: 'active', name: 'Ativo'},
  'inactive': {id: 31, value: 'inactive', name: 'Inativo'}
}

export type Assessment = {
  id?: ID
  title: string;
  description: string;
  status_id: number;
  status_name: string;
  weight: number;
  answer_value: string;
  type_id: number;
  type_name: string;
}

export type questionsQueryResponse = Response<Array<Assessment>>

export const initialAssessment: Assessment = {
  title: '',
  description: '',
  status_id: 0,
  type_id: 1,
  weight: 1,
  answer_value: '',
  status_name: '',
  type_name: ''
}

