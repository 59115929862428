import {FC} from 'react'
import {Meetings, MeetingsStatus} from '../../core/_models'

type Props = {
  category: Meetings
}

const MeetingsStatusCell: FC<Props> = ({category}) => {
  let look: string = 'light-primary'
  switch (category.status_name) {
    case MeetingsStatus.active.value:
      look = 'light-success'
      break
    case MeetingsStatus.inactive.value:
      look = 'light-secondary text-dark'
      break
    case MeetingsStatus.canceled.value:
      look = 'light-danger'
      break
  }

  return (
    <>
      {category && (
        <div className={`badge badge-${look} fw-bolder`}>
          {category.status_name.charAt(0).toUpperCase() + category.status_name.slice(1)}
        </div>
      )}
    </>
  )
}

export {MeetingsStatusCell}
