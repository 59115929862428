/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/graphs/gra012.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-graph-up-arrow'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Cadastros</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/apps/user-management'
        title='Usuários'
        fontIcon='bi-person'
        icon='/media/icons/duotune/communication/com006.svg'
      />
      <SidebarMenuItem
        to='/apps/guardians-management'
        title='Responsáveis'
        fontIcon='bi-person'
        icon='/media/icons/duotune/communication/com006.svg'
      />
      <SidebarMenuItem
        to='apps/childrens-management'
        title='Crianças'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      />
      <SidebarMenuItem
        to='/apps/videos-management/videos'
        title='Vídeos'
        icon='/media/icons/duotune/general/gen062.svg'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/apps/news-management'
        title='Notícias'
        fontIcon='bi-folder2-open'
        icon='/media/icons/duotune/abstract/abs027.svg'
      />
    </>
  )
}

export {SidebarMenuMain}
