import {ID, Response} from '../../../../../../_metronic/helpers'

export const CategoryStatus = {
  'active': {id: 7, value: 'active', name: 'Ativo'},
  'inactive': {id: 22, value: 'inactive', name: 'Inativo'},
  'draft': {id: 3, value: 'draft', name: 'Rascunho'},
}

export type Category = {
  id?: ID
  name: string
  status_name: string
  status_id: number
  type: string
  image_thumb: string
  position: number
}

export type Status = {
  id?: ID
  name?: string
}


export type CategoriesQueryResponse = Response<Array<Category>>

export const initialCategory: Category = {
  name: '',
  status_name: CategoryStatus.active.name,
  status_id: 3,
  type: '',
  image_thumb: '',
  position: 1
}

