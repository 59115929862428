import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {MeetingsListHeader} from './components/header/MeetingsListHeader'
import {MeetingsTable} from './table/MeetingsTable'
import {MeetingsEditModal} from './meetings-edit-modal/MeetingsEditModal'
import {KTCard} from '../../../../../_metronic/helpers'

type PropTypes = {
  /** Shows the [Add User] button inside the header on the top right corner */
  showAddMeetings?: boolean
  /** An array of columns that you don't want shown in the user list */
  hiddenColumns?: string[]
}

const CategoriesList = ({showAddMeetings = true, hiddenColumns = []}: PropTypes) => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <MeetingsListHeader showAddMeetings={showAddMeetings} />
        <MeetingsTable hiddenColumns={hiddenColumns} />
      </KTCard>
      {itemIdForUpdate !== undefined && <MeetingsEditModal />}
    </>
  )
}

const MeetingsListWrapper = ({showAddMeetings = true, hiddenColumns = []}: PropTypes) => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <CategoriesList showAddMeetings={showAddMeetings} hiddenColumns={hiddenColumns} />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {MeetingsListWrapper}
