import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import { QuestionsListFilter } from './QuestionsListFilter'

type PropTypes = {
  showAddquestions?: boolean
}

const QuestionsListToolbar = ({showAddquestions = true}: PropTypes) => {
  const {setItemIdForUpdate} = useListView()
  const openAddCategoryModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <QuestionsListFilter />
      {/* begin::Add category */}
      {showAddquestions && (
        <button type='button' className='btn btn-primary' onClick={openAddCategoryModal}>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          Adicionar Nova Pergunta
        </button>
      )}
      {/* end::Add category */}
    </div>
  )
}

export {QuestionsListToolbar}
