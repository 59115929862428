import {useLocation} from 'react-router-dom'

const headerTitle: Record<string, string> = {
  '/dashboard': 'Dashboard',
  '/apps/user-management/users': 'Gerenciamento de Usuários',
  '/documents': 'Gerenciamento de Documentos',
  'Notícias': 'Gerenciamento de Notícias',
  '/course-management/courses': 'Gerenciamento de Cursos e Aulas',
}

export function MenuInner() {
  const location = useLocation()

  return (
    <div className='d-flex align-items-center'>
      <h2>{headerTitle[location.pathname]}</h2>
    </div>
  )
}
