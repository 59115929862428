import {ID, Response} from '../../../../../../_metronic/helpers'

export const NewsStatus = {
  'active': {id: 14, value: 'active', name: 'Ativo'},
  'inactive': {id: 15, value: 'inactive', name: 'Inativo'}
}

export const Categories = {
  'Asma': {id:1	, value: 'Asma', name: 'Asma'},
  'Saúde respiratória': {id:2	, value: 'Saúde respiratória', name: 'Saúde respiratória'},
  'Alergia respiratória': {id:3	, value: 'Alergia respiratória', name: 'Alergia respiratória'},
  'Bronquite': {id:4	, value: 'Bronquite', name: 'Bronquite'},
  'Higiene brônquica': {id:5	, value: 'Higiene brônquica', name: 'Higiene brônquica'},
  'Bronquiolite': {id:6	, value: 'Bronquiolite', name: 'Bronquiolite'},
  'Artigos e notícias': {id:7	, value: 'Artigos e notícias', name: 'Artigos e notícias'},
  'Tratamentos': {id:8	, value: 'Tratamentos', name: 'Tratamentos'},
  'Exercícios': {id:9	, value: 'Exercícios', name: 'Exercícios'},
  'Inalação': {id:10, value: 'Inalação', name: 'Inalação'},
  'Oxigenoterapia': {id:11, value: 'Oxigenoterapia', name: 'Oxigenoterapia'},
  'Broncodilatadores': {id:12, value: 'Broncodilatadores', name: 'Broncodilatadores'},
  'Infecções virais agudas': {id:13, value: 'Infecções virais agudas', name: 'Infecções virais agudas'},
  'Espaçador inalatório': {id:14, value: 'Espaçador inalatório', name: 'Espaçador inalatório'},
  'Respiração': {id:15, value: 'Respiração', name: 'Respiração'},
  'Aspiração traqueal': {id:16, value: 'Aspiração traqueal', name: 'Aspiração traqueal'},
  'Pneumonia': {id:17, value: 'Pneumonia', name: 'Pneumonia'},
  'Alergia respiratoria': {id:18, value: 'Alergia respiratória', name: 'Alergia respiratória'},
  'Medicamentos': {id:19, value: 'Medicamentos', name: 'Medicamentos'},
  'Reabilitação pulmonar': {id:20, value: 'Reabilitação pulmonar', name: 'Reabilitação pulmonar'},
  'Prevenção': {id:21, value: 'Prevenção', name: 'Reabilitação pulmonar'},
  'Dispneia (falta de ar)': {id:22, value: 'Dispneia (falta de ar)', name: 'Dispneia (falta de ar)'},
  'Nebulização': {id:23, value: 'Nebulização', name: 'Nebulização'},
  'Terapia pulmonar': {id:24, value: 'Terapia pulmonar', name: 'Terapia pulmonar'},
}

export type News = {
  id: number;
  title: string;
  short_description: string;
  description: string;
  image_highlight: string;
  image_thumb: string;
  status_id: number;
  status_name: string;
  author_id: number;
  author_name: string;
  category_id: number;
  category_name: string;
}

export type Status = {
  id?: ID
  name?: string
}


export type NewsQueryResponse = Response<Array<News>>

export const initialNews: News = {
  id: 0,
  title: '',
  short_description: '',
  description: '',
  image_highlight: '',
  image_thumb: '',
  status_id: 14,
  status_name: '',
  author_id: 0,
  author_name: '',
  category_id: 1,
  category_name: ''
}

export type ImageUploadResponse = Response<{file_name: string}>
