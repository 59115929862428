import {useListView} from '../../core/ListViewProvider'
import {QuestionsListToolbar} from './QuestionsListToolbar'
import {ListGrouping} from './ListGrouping'
import {SearchComponent} from './SearchComponent'

type PropTypes = {
  showAddquestions?: boolean
}

const QuestionsListHeader = ({showAddquestions = true}: PropTypes) => {
  const {selected} = useListView()

  return (
      <div className='card-header border-0 pt-6'>
        <SearchComponent />
        {/* begin::Card toolbar */}
        <div className='card-toolbar'>
          {/* begin::Group actions */}
          {selected.length > 0 ? (
            <ListGrouping />
          ) : (
            <QuestionsListToolbar showAddquestions={showAddquestions} />
          )}
          {/* end::Group actions */}
        </div>
        {/* end::Card toolbar */}
      </div>
  )
}

export {QuestionsListHeader}
