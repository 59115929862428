import {FC} from 'react'
import {Guardians, GuardianStatus} from '../../core/_models'

type Props = {
  guardians: Guardians
}

const StatusCell: FC<Props> = ({guardians}) => {
  let look: string = 'light-primary'
  switch (guardians.status_name) {
    case GuardianStatus.active.value:
      look = 'light-success'
      break
    case GuardianStatus.inactive.value:
      look = 'light-secondary text-dark'
      break
  }

  return (
    <>
      {guardians && (
        <div className={`badge badge-${look} fw-bolder`}>
          {guardians.status_name.charAt(0).toUpperCase() + guardians.status_name.slice(1)}
        </div>
      )}
    </>
  )
}

export {StatusCell}
