import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {AssessmentsListWrapper} from './assessments-list/AssessmentsList'
import {QuestionsListWrapper} from './questions-list/QuestionsList'

const AssessmentsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Gerenciamento de Testes',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const questionsBreadcrumbs: Array<PageLink> = [
  ...AssessmentsBreadcrumbs,
  {
    title: 'Lista de Testes',
    path: '/apps/assessments-management/assessments',
    isSeparator: false,
    isActive: true,
  },
  {
    title: 'Lista',
    path: '/:id/questions',
    isActive: false,
    isSeparator: true,
  }
]

const AssessmentsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/assessments'
          element={ 
            <>
              <PageTitle breadcrumbs={AssessmentsBreadcrumbs}>Lista de Testes</PageTitle>
              <AssessmentsListWrapper />
            </>
          }
        />
      </Route>
      <Route element={<Outlet />}>
        <Route
          path='/:id/questions'
          element={
            <>
              <PageTitle breadcrumbs={questionsBreadcrumbs}>Lista de Perguntas</PageTitle>
              <QuestionsListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/assessments-management/assessments' />} />
    </Routes>
  )
}

export default AssessmentsPage
