import {ID, Response} from '../../../../../../_metronic/helpers'

export const GuardianStatus = {
  'active': {id: 5, value: 'active', name: 'Ativo'},
  'inactive': {id: 6, value: 'inactive', name: 'Inativo'}
}

export type Guardians = {
  id?: ID
  children_id: number
  children_name: string
  guardian_id: number
  guardian_name: string
  video_name: string
  created_at: Date
}

export type Status = {
  id?: ID
  name?: string
}

export type GuardiansQueryResponse = Response<Array<Guardians>>

export const initialAuthors: Guardians = {
  children_id: 0,
  children_name: '',
  guardian_id: 0,
  guardian_name: '',
  video_name: '',
  created_at: new Date(Date.now())
}

export type ImageUploadResponse = Response<{file_name: string}>
