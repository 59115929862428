import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../../_metronic/helpers'
import {
  News,
  NewsQueryResponse,
  ImageUploadResponse,
} from './_models'

const API_URL = process.env.REACT_APP_API_URL

const getFilter = (query: any): Promise<NewsQueryResponse> => {
  return axios
    .get(`${API_URL}/admin/news/filter`, {params: query})
    .then((d: AxiosResponse<NewsQueryResponse>) => d.data)
}

const create = (meetings: News): Promise<News | undefined> => {
  return axios
    .post(`${API_URL}/admin/news/create`, meetings)
    .then((response: AxiosResponse<Response<News>>) => response.data)
    .then((response: Response<News>) => response.data)
}

const update = (meetings: News): Promise<News | undefined> => {
  return axios
    .post(`${API_URL}/admin/news/update/${meetings.id}`, meetings)
    .then((response: AxiosResponse<Response<News>>) => response.data)
    .then((response: Response<News>) => response.data)
}

const uploadImage = (file: File): Promise<ImageUploadResponse | undefined> => {
  const formData = new FormData()
  formData.append('image', file)
  return axios.patch(`${API_URL}/admin/news/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export {
  create,
  update,
  getFilter,
  uploadImage
}
