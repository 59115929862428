import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {MeetingsListWrapper} from './meetings-list/MeetingsList'

const meetingsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Gerenciamento de Reunião',
    path: '/apps/meetings-management/meetings',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const MeetingsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='meetings'
          element={
            <>
              <PageTitle breadcrumbs={meetingsBreadcrumbs}>Lista de Reunião</PageTitle>
              <MeetingsListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/meetings-management/meetings' />} />
    </Routes>
  )
}

export default MeetingsPage
