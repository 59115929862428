import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {AssessmentsListHeader} from './components/header/AssessmentsListHeader'
import {AssessmentsTable} from './table/AssessmentsTable'
import {AssessmentsEditModal} from './assessments-edit-modal/AssessmentsEditModal'
import {KTCard} from '../../../../../_metronic/helpers'

type PropTypes = {
  /** Shows the [Add User] button inside the header on the top right corner */
  showAddassessments?: boolean
  /** An array of columns that you don't want shown in the user list */
  hiddenColumns?: string[]
}

const AssessmentsList = ({showAddassessments = true, hiddenColumns = []}: PropTypes) => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <AssessmentsListHeader showAddassessments={showAddassessments} />
        <AssessmentsTable hiddenColumns={hiddenColumns} />
      </KTCard>
      {itemIdForUpdate !== undefined && <AssessmentsEditModal />}
    </>
  )
}

const AssessmentsListWrapper = ({showAddassessments = true, hiddenColumns = []}: PropTypes) => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <AssessmentsList showAddassessments={showAddassessments} hiddenColumns={hiddenColumns} />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {AssessmentsListWrapper}
