// @ts-nocheck
import {Column} from 'react-table'
import {MeetingsInfoCell} from './MeetingsInfoCell'
import {MeetingsStatusCell} from './MeetingsStatusCell'
// import {UserSelectionCell} from './UserSelectionCell'
import {MeetingsCustomHeader} from './MeetingsCustomHeader'
// import {UserSelectionHeader} from './UserSelectionHeader'
import {Category} from '../../core/_models'
import { MeetingsActionsCell } from './MeetingsActionsCell'
import { MeetingsGenericCell } from './MeetingsGenericCell'

const categoriesColumns: ReadonlyArray<Column<Category>> = [
  // {
  //   Header: (props) => <UserSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => (
      <MeetingsCustomHeader tableProps={props} title='Id' className='min-w-25px' />
    ),
    id: 'id',
    Cell: ({...props}) => <MeetingsGenericCell labelName={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => (
      <MeetingsCustomHeader tableProps={props} title='Categoria' className='min-w-125px' />
    ),
    id: 'name',
    Cell: ({...props}) => <MeetingsInfoCell meetings={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <MeetingsCustomHeader tableProps={props} title='Status' className='min-w-90px' />
    ),
    id: 'status_name',
    Cell: ({...props}) => <MeetingsStatusCell category={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <MeetingsCustomHeader tableProps={props} title='Tipo' className='min-w-90px' />
    ),
    id: 'type',
    Cell: ({...props}) => <MeetingsGenericCell labelName={props.data[props.row.index].type} />,
  },
  {
    Header: (props) => (
      <MeetingsCustomHeader tableProps={props} title='Imagem' className='min-w-90px' />
    ),
    id: 'image_thumb',
    Cell: ({...props}) => <MeetingsGenericCell labelName={props.data[props.row.index].image_thumb} />,
  },
  {
    Header: (props) => (
      <MeetingsCustomHeader tableProps={props} title='Ordem' className='min-w-20px' />
    ),
    id: 'position',
    Cell: ({...props}) => <MeetingsGenericCell labelName={props.data[props.row.index].position} />,
  },
  {
    Header: (props) => (
      <MeetingsCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <MeetingsActionsCell
        id={props.data[props.row.index].id}
      />
    ),
  },
]

export {categoriesColumns}
