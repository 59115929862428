import {useListView} from '../../core/ListViewProvider'
import {AssessmentsListToolbar} from './AssessmentsListToolbar'
import {ListGrouping} from './ListGrouping'
import {SearchComponent} from './SearchComponent'

type PropTypes = {
  showAddassessments?: boolean
}

const AssessmentsListHeader = ({showAddassessments = true}: PropTypes) => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <SearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? (
          <ListGrouping />
        ) : (
          <AssessmentsListToolbar showAddassessments={showAddassessments} />
        )}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {AssessmentsListHeader}
