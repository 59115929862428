// @ts-nocheck
import {Column} from 'react-table'
import {AssessmentsInfoCell} from './AssessmentsInfoCell'
import {AssessmentsCustomHeader} from './AssessmentsCustomHeader'
// import {UserSelectionHeader} from './UserSelectionHeader'
import {Assessment} from '../../core/_models'
import { AssessmentsIdCell } from './AssessmentsIdCell'
import { AssessmentsActionsCell } from './AssessmentsActionsCell'
import { AssessmentsGenericCell } from './AssessmentsGenericCell'
import { AssessmentsStatusCell } from './AssessmentsStatusCell'

const assessmentsColumns: ReadonlyArray<Column<Assessment>> = [
  // {
  //   Header: (props) => <UserSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => (
      <AssessmentsCustomHeader tableProps={props} title='Id' className='min-w-25px' />
    ),
    id: 'id',
    Cell: ({...props}) => <AssessmentsIdCell assessments={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <AssessmentsCustomHeader tableProps={props} title='Nome' className='min-w-125px' />
    ),
    id: 'name',
    Cell: ({...props}) => <AssessmentsInfoCell assessments={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <AssessmentsCustomHeader tableProps={props} title='Total Questões' className='min-w-90px' />
    ),
    id: 'questions_number',
    Cell: ({...props}) => <AssessmentsGenericCell labelName={props.data[props.row.index].questions_number} />,
  },
  {
    Header: (props) => (
      <AssessmentsCustomHeader tableProps={props} title='Total Tentativas' className='min-w-20px' />
    ),
    id: 'attempts_limit',
    Cell: ({...props}) => <AssessmentsGenericCell labelName={props.data[props.row.index].attempts_limit} />,
  },
  {
    Header: (props) => (
      <AssessmentsCustomHeader tableProps={props} title='Status' className='min-w-90px' />
    ),
    id: 'status_name',
    Cell: ({...props}) => <AssessmentsStatusCell category={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <AssessmentsCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <AssessmentsActionsCell
        id={props.data[props.row.index].id}
      />
    ),
  },
]

export {assessmentsColumns}
