import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../../_metronic/helpers'
import {
  Category,
  CategoriesQueryResponse,
} from './_models'

const API_URL = process.env.REACT_APP_API_URL

const getCategories = (): Promise<CategoriesQueryResponse> => {
  return axios
    .get(`${API_URL}/admin/categories/all`)
    .then((d: AxiosResponse<CategoriesQueryResponse>) => d.data)
}

const getCategoriesFilter = (query: any): Promise<CategoriesQueryResponse> => {
  return axios
    .get(`${API_URL}/admin/categories/filter`, {params: query})
    .then((d: AxiosResponse<CategoriesQueryResponse>) => d.data)
}

const createCategory = (caegory: Category): Promise<Category | undefined> => {
  return axios
    .post(`${API_URL}/admin/categories/create`, caegory)
    .then((response: AxiosResponse<Response<Category>>) => response.data)
    .then((response: Response<Category>) => response.data)
}

const updateCategory = (caegory: Category): Promise<Category | undefined> => {
  return axios
    .post(`${API_URL}/admin/categories/update/${caegory.id}`, caegory)
    .then((response: AxiosResponse<Response<Category>>) => response.data)
    .then((response: Response<Category>) => response.data)
}

export {
  getCategories,
  createCategory,
  updateCategory,
  getCategoriesFilter
}
