import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty} from '../../../../../../_metronic/helpers'
import {initialCategory, Category} from '../core/_models'
import clsx from 'clsx'
import {toast} from 'react-toastify'
import {useListView} from '../core/ListViewProvider'
import {CategoriesListLoading} from '../components/loading/CategoriesListLoading'
import {createCategory, updateCategory} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'

type Props = {
  isCategoryLoading: boolean
  response: any
  status: any
}

const editCategorySchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Mínimo 3 caracteres')
    .max(50, 'Maximum 50 caracteres')
    .required('Nome é obrigatório'),
  type: Yup.string()
    .min(3, 'Mínimo 3 caracteres')
    .max(50, 'Maximum 50 caracteres')
    .required('Tipo é obrigatório'),
})

const CategoryEditModalForm: FC<Props> = ({response, isCategoryLoading}) => {
  const category = response.length > 0 ? response[0] : response
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()

  const [categoryForEdit] = useState<Category>({
    ...category,
    name: category.name || initialCategory.name,
    status_id: category.status_id || initialCategory.status_id,
    type: category.type  || initialCategory.type,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: categoryForEdit,
    validationSchema: editCategorySchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateCategory(values)
          toast.success(`Categoria ${values.name} atualizado com sucesso`)
        } else {
          await createCategory(values)
          toast.success(`Categoria ${values.name} criado com sucesso`)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y ps-1'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Nome da Categoria</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Nome da Categoria'
              {...formik.getFieldProps('name')}
              type='text'
              name='name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.name && formik.errors.name},
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isCategoryLoading}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          <div className='row mb-7 m-0 p-0'>
            <div className='col-sm-5 ps-0'>
              <label className='fw-bold fs-6 mb-2'>Status</label>
              <select
                className='form-control form-control-solid form-select mb-3 mb-lg-0'
                {...formik.getFieldProps('status_id')}
                name='status_id'
                disabled={formik.isSubmitting || isCategoryLoading}
              >
                <option disabled>
                  Selecione o status
                </option>
               <option value={'7'}>Ativo</option>
               <option value={'22'}>Inativo</option>
               <option value={'3'}>Rascunho</option>
              </select>
              {formik.touched.status_id && formik.errors.status_id && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.status_id}</span>
                </div>
              )}
            </div>
          </div>
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Tipo</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Tipo da Categoria'
              {...formik.getFieldProps('type')}
              type='text'
              name='type'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.type && formik.errors.type},
                {
                  'is-valid': formik.touched.type && !formik.errors.type,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isCategoryLoading}
            />
            {formik.touched.type && formik.errors.type && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.type}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Imagem</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Imagem da Categoria'
              {...formik.getFieldProps('image_thumb')}
              type='text'
              name='image_thumb'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.image_thumb && formik.errors.image_thumb},
                {
                  'is-valid': formik.touched.image_thumb && !formik.errors.image_thumb,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isCategoryLoading}
            />
            {formik.touched.image_thumb && formik.errors.image_thumb && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.image_thumb}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Posição</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Imagem da Categoria'
              {...formik.getFieldProps('position')}
              type='text'
              name='position'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.position && formik.errors.position},
                {
                  'is-valid': formik.touched.position && !formik.errors.position,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isCategoryLoading}
            />
            {formik.touched.position && formik.errors.position && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.position}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
        </div>
        {/* end::Scroll */}
              
        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isCategoryLoading}
          >
            Cancelar
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isCategoryLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Confirmar</span>
            {(formik.isSubmitting || isCategoryLoading) && (
              <span className='indicator-progress'>
                Por favor, aguarde...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isCategoryLoading) && <CategoriesListLoading />}
    </>
  )
}

export {CategoryEditModalForm}
