import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {MeetingsListFilter} from './MeetingsListFilter'

type PropTypes = {
  showAddMeetings?: boolean
}

const MeetingsListToolbar = ({showAddMeetings = true}: PropTypes) => {
  const {setItemIdForUpdate} = useListView()
  const openAddMeetingsModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <MeetingsListFilter />
      {/* begin::Add category */}
      {showAddMeetings && (
        <button type='button' className='btn btn-primary' onClick={openAddMeetingsModal}>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          Adicionar Reunião
        </button>
      )}
      {/* end::Add category */}
    </div>
  )
}

export {MeetingsListToolbar}
