import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {DocumentsListHeader} from './components/header/DocumentsListHeader'
import {DocumentsTable} from './table/DocumentsTable'
import {EditModal} from './edit-modal/EditModal'
import {KTCard} from '../../../../../_metronic/helpers'

type PropTypes = {
  /** Shows the [Add User] button inside the header on the top right corner */
  showAddDocuments?: boolean
  /** An array of columns that you don't want shown in the user list */
  hiddenColumns?: string[]
}

const DocumentsList = ({showAddDocuments = true, hiddenColumns = []}: PropTypes) => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <DocumentsListHeader showAddDocuments={showAddDocuments} />
        <DocumentsTable hiddenColumns={hiddenColumns} />
      </KTCard>
      {itemIdForUpdate !== undefined && <EditModal />}
    </>
  )
}

const ListWrapper = ({showAddDocuments = true, hiddenColumns = []}: PropTypes) => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <DocumentsList showAddDocuments={showAddDocuments} hiddenColumns={hiddenColumns} />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ListWrapper}
