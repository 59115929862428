import {useListView} from '../../core/ListViewProvider'
import {DocumentsListToolbar} from './DocumentsListToolbar'
import {DocumentsListGrouping} from './DocumentsListGrouping'
import {DocumentsSearchComponent} from './DocumentsSearchComponent'

type PropTypes = {
  showAddDocuments?: boolean
}

const DocumentsListHeader = ({showAddDocuments = true}: PropTypes) => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <DocumentsSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? (
          <DocumentsListGrouping />
        ) : (
          <DocumentsListToolbar showAddDocuments={showAddDocuments} />
        )}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {DocumentsListHeader}
