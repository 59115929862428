import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {imageMeetingsPath, isNotEmpty} from '../../../../../../_metronic/helpers'
import {initialNews, News} from '../core/_models'
import clsx from 'clsx'
import {toast} from 'react-toastify'
import {useListView} from '../core/ListViewProvider'
import {DocumentsListLoading} from '../components/loading/DocumentsListLoading'
import {create, update, uploadImage} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'

type Props = {
  isLoading: boolean
  response: any
  status: any
}

const editDocumentsSchema = Yup.object().shape({
  
})

const EditModalForm: FC<Props> = ({response, isLoading}) => {
  const documents = response.length > 0 ? response[0] : response
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const blankImg = '/media/icons/duotune/general/gen046.svg'

  const [documentsForEdit] = useState<News>({
    ...documents,
    category_id: documents.category_id  || initialNews.category_id,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: documentsForEdit,
    validationSchema: editDocumentsSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await update(values)
          toast.success(`News ${values.title} atualizado com sucesso`)
        } else {
          await create(values)
          toast.success(`News ${values.title} criado com sucesso`)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  const changeImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return
    const newImage = await uploadImage(event.target.files![0])
    if (newImage?.data?.file_name) {
      formik.setFieldValue(event.target.name, newImage?.data?.file_name)
    }
  }
 
  const thumbImage = imageMeetingsPath('https://getinoxy.s3.sa-east-1.amazonaws.com/news', formik.values.image_thumb)

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y ps-1'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Título</label>
            <input
              placeholder='Título que será exibido para os usuários'
              {...formik.getFieldProps('title')}
              type='text'
              name='title'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.title && formik.errors.title},
                {
                  'is-valid': formik.touched.title && !formik.errors.title,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isLoading}
            />
            {formik.touched.title && formik.errors.title && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.title}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Resumo da Notícia</label>
            <input
              placeholder='Resumo que será exibido para os usuários'
              {...formik.getFieldProps('short_description')}
              type='text'
              name='short_description'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.short_description && formik.errors.short_description},
                {
                  'is-valid': formik.touched.short_description && !formik.errors.short_description,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isLoading}
            />
            {formik.touched.short_description && formik.errors.short_description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.short_description}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Descrição Completa</label>
            {/* end::Label */}

            {/* begin::Input */}
            <textarea
              placeholder='Descrição que será exibida para os usuários'
              {...formik.getFieldProps('description')}
              rows={5}
              name='description'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.description && formik.errors.description},
                {
                  'is-valid': formik.touched.description && !formik.errors.description,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isLoading}
              />
            {formik.touched.description && formik.errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.description}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          
          <div className='row mb-7 m-0 p-0'>
            <div className='col-sm-5 ps-0'>
              <label className='fw-bold fs-6 mb-2'>Status</label>
              <select
                className='form-control form-control-solid form-select mb-3 mb-lg-0'
                {...formik.getFieldProps('status_id')}
                name='status_id'
                disabled={formik.isSubmitting || isLoading}
              >
                <option disabled>
                  Selecione o status
                </option>
               <option value={'14'}>Ativo</option>
               <option value={'15'}>Inativo</option>
              </select>
              {formik.touched.status_id && formik.errors.status_id && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.status_id}</span>
                </div>
              )}
            </div>
          </div>

          <div className='row mb-7 m-0 p-0'>
            <div className='col-sm-5 ps-0'>
              <label className='fw-bold fs-6 mb-2'>Categoria</label>
              <select
                className='form-control form-control-solid form-select mb-3 mb-lg-0'
                {...formik.getFieldProps('category_id')}
                name='category_id'
                disabled={formik.isSubmitting || isLoading}
              >
                <option disabled>
                  Selecione a categoria
                </option>
                <option value={'1'}>Asma</option>
                <option value={'2'}>Saúde respiratória</option>
                <option value={'3'}>Alergia respiratória</option>
                <option value={'4'}>Bronquite</option>
                <option value={'5'}>Higiene brônquica</option>
                <option value={'6'}>Bronquiolite</option>
                <option value={'7'}>Artigos e notícias</option>
                <option value={'8'}>Tratamentos</option>
                <option value={'9'}>Exercícios</option>
                <option value={'10'}>	Inalação</option>
                <option value={'11'}>	Oxigenoterapia</option>
                <option value={'12'}>	Broncodilatadores</option>
                <option value={'13'}>	Infecções virais agudas</option>
                <option value={'14'}>	Espaçador inalatório</option>
                <option value={'15'}>	Respiração</option>
                <option value={'16'}>	Aspiração traqueal</option>
                <option value={'17'}>	Pneumonia</option>
                <option value={'18'}>	Alergia respiratória</option>
                <option value={'19'}>	Medicamentos</option>
                <option value={'20'}>	Reabilitação pulmonar</option>
                <option value={'21'}>	Prevenção</option>
                <option value={'22'}>	Dispneia (falta de ar)</option>
                <option value={'23'}>	Nebulização</option>
                <option value={'24'}>	Terapia pulmonar</option>
              </select>
              {formik.touched.category_id && formik.errors.category_id && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.category_id}</span>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-7 m-0 p-0'>
            {/* begin::Input group Cover Image */}
            <div className='col-sm-6 ps-0'>
              {/* begin::Label */}
              <label className='d-block fw-bold fs-6 mb-5'>Imagem da notícia</label>
              {/* end::Label */}

              {/* begin::Image input */}
              <div
                className='image-input image-input-outline ps-5'
                data-kt-image-input='true'
                style={{backgroundImage: `url('${blankImg}')`}}
              >
                {/* begin::Preview existing image */}
                <div
                  className='image-input-wrapper w-250px h-125px'
                  style={{
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    backgroundImage: `url('${thumbImage}')`,
                  }}
                ></div>
                {/* end::Preview existing image */}

                {/* begin::Label */}
                <label
                  className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                  data-kt-image-input-action='change'
                  data-bs-toggle='tooltip'
                  title='Mudar imagem'
                >
                  <i className='bi bi-pencil-fill fs-7'></i>

                  <input
                    type='file'
                    name='image_thumb'
                    accept='.png, .jpg, .jpeg'
                    onChange={(event) => changeImage(event)}
                  />
                  <input type='hidden' name='image_thumb_hidden' />
                </label>
              </div>
              {/* begin::Hint */}
              <div className='form-text'>Tipos permitidos: png, jpg, jpeg.</div>
              {/* end::Hint */}
            </div>
          </div>
        </div>
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isLoading}
          >
            Cancelar
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Confirmar</span>
            {(formik.isSubmitting || isLoading) && (
              <span className='indicator-progress'>
                Por favor, aguarde...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isLoading) && <DocumentsListLoading />}
    </>
  )
}

export {EditModalForm}
