import {FC} from 'react'
import {News, NewsStatus} from '../../core/_models'

type Props = {
  documents: News
}

const DocumentsStatusCell: FC<Props> = ({documents}) => {
  let look: string = 'light-primary'
  switch (documents.status_name) {
    case NewsStatus.active.value:
      look = 'light-success'
      break
    case NewsStatus.inactive.value:
      look = 'light-secondary text-dark'
      break
  }

  return (
    <>
      {documents && (
        <div className={`badge badge-${look} fw-bolder`}>
          {documents.status_name.charAt(0).toUpperCase() + documents.status_name.slice(1)}
        </div>
      )}
    </>
  )
}

export {DocumentsStatusCell}
