/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Assessment} from '../../core/_models'
import {useListView} from '../../core/ListViewProvider'

type Props = {
  assessments: Assessment
}

const AssessmentsInfoCell: FC<Props> = ({assessments}) => {
  const {setItemIdForUpdate} = useListView()
  const openEditModal = () => {
    setItemIdForUpdate(assessments.id)
  }

  return (
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      <div className='d-flex flex-column'>
        <a
          onClick={openEditModal}
          role='button'
          className='text-gray-800 text-hover-primary mb-1 pointer-cli'
        >
          {assessments.name}
        </a>
      </div>
    </div>
  )
}

export {AssessmentsInfoCell}
