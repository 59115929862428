import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../../_metronic/helpers'
import {
  Meetings,
  MeetingsQueryResponse,
  ImageUploadResponse,
} from './_models'

const API_URL = process.env.REACT_APP_API_URL

const getMeetingsFilter = (query: any): Promise<MeetingsQueryResponse> => {
  return axios
    .get(`${API_URL}/admin/meetings/filter`, {params: query})
    .then((d: AxiosResponse<MeetingsQueryResponse>) => d.data)
}

const createMeetings = (meetings: Meetings): Promise<Meetings | undefined> => {
  return axios
    .post(`${API_URL}/admin/meetings/create`, meetings)
    .then((response: AxiosResponse<Response<Meetings>>) => response.data)
    .then((response: Response<Meetings>) => response.data)
}

const updateMeetings = (meetings: Meetings): Promise<Meetings | undefined> => {
  return axios
    .post(`${API_URL}/admin/meetings/update/${meetings.id}`, meetings)
    .then((response: AxiosResponse<Response<Meetings>>) => response.data)
    .then((response: Response<Meetings>) => response.data)
}

const uploadImage = (file: File): Promise<ImageUploadResponse | undefined> => {
  const formData = new FormData()
  formData.append('image', file)
  return axios.patch(`${API_URL}/admin/meetings/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export {
  createMeetings,
  updateMeetings,
  getMeetingsFilter,
  uploadImage
}
