import axios from "axios"
import {
  Assessment,
  DashboardCoursesCard,
  DashboardUsersCard,
  WatchedVideosByCompanyData,
  WatchedVideosByUserData,
  WatchedVideosData
} from "./_models"

const API_URL = process.env.REACT_APP_API_URL

export const GETCardUsersData = async () => {
  return axios.get<DashboardUsersCard>(`${API_URL}/admin/dashboard/card1
  `).then(response => response.data)
}
export const GETCardCoursesData = async () => {
  return axios.get<DashboardCoursesCard>(`${API_URL}/admin/dashboard/card2
  `).then(response => response.data)
}

export const GETWatchedVideosData = async () => {
  return axios.get<WatchedVideosData[]>(`${API_URL}/admin/dashboard/graphic-watched-videos
  `).then(response => response.data)
}

export const GETWatchedVideosByUserData = async () => {
  return axios.get<WatchedVideosByUserData[]>(
    `${API_URL}/admin/dashboard/graphic-videos-by-user`
  ).then(response => response.data)
}

export const GETWatchedVideosByCompanyData = async () => {
  return axios.get<WatchedVideosByCompanyData[]>(
    `${API_URL}/admin/dashboard/history-registrations`
  ).then(response => response.data)
}

export const GETAssessments = async () => {
  return axios.get<Assessment[]>(
    `${API_URL}/admin/dashboard/card-assessments`
  ).then(response => response.data)
}