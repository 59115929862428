import {useListView} from '../../core/ListViewProvider'
import {CategoriesListToolbar} from './CategoriesListToolbar'
import {CategoriesListGrouping} from './CategoriesListGrouping'
import {CategoriesSearchComponent} from './CategoriesSearchComponent'

type PropTypes = {
  showAddCategory?: boolean
}

const CategoriesListHeader = ({showAddCategory = true}: PropTypes) => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <CategoriesSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? (
          <CategoriesListGrouping />
        ) : (
          <CategoriesListToolbar showAddCategory={showAddCategory} />
        )}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {CategoriesListHeader}
