import {FC} from 'react'
import { Category } from '../../core/_models'

type Props = {
  category: Category
}

const CategoryIdCell: FC<Props> = ({category}) => {
  return (
    <>
      {
        <div className='d-flex align-items-center'>
        {/* begin:: Avatar */}
        <div className='d-flex flex-column'>
            {category.id}
        </div>
      </div>
      }
    </>
  )
}

export {CategoryIdCell}
