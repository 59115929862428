/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { login } from '../core/_requests'
import { useAuth } from '../core/Auth'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email inválido')
    .min(3, 'Mínimo 3 caracteres')
    .max(50, 'Máximo 50 caracteres')
    .required('Email é obrigatório'),
  password: Yup.string()
    .min(3, 'Mínimo 3 caracteres')
    .max(50, 'Máximo 50 caracteres')
    .required('Senha é obrigatória'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const { saveAuth, setCurrentUser } = useAuth()
  const [loading, setLoading] = useState(false)
  const [passwordType, setPasswordType] = useState('password')

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const { data: auth } = await login(values.email, values.password)
        saveAuth(auth)
        setCurrentUser(auth.user)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('Login incorreto')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  function togglePasswordType() {
    setPasswordType(passwordType === 'password' ? 'text' : 'password')
  }

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <div className='text-white  fs-4'>
        Voltada para crianças, adolescentes, pais e cuidadores e também profissionais de saúde.
        </div>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text fw-bold'>{formik.status}</div>
        </div>
      ) : (
        <div className='fw-bold fs-4 pb-4 text-center' style={{ color: '#ADB0C0' }}>
          Login na Plataforma
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
          style={{ backgroundColor: '#F5F6F8' }}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        {/* <input type={passwordType} autoComplete='off' {...formik.getFieldProps('password')} /> */}
        <div className='input-group'>
          <input
            className={clsx(
              'form-control',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
            type={passwordType}
            autoComplete='off'
            id='specificPassword'
            placeholder='Senha'
            {...formik.getFieldProps('password')}
            style={{ backgroundColor: '#F5F6F8' }}
          />
          <span className='input-group-text bg-gray-300'>
            <i
              onClick={togglePasswordType}
              role='button'
              className={`fa ${passwordType === 'password' ? 'fa-eye-slash' : 'fa-eye'
                } d-flex w-20px justify-content-center`}
            />
          </span>
        </div>
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        <Link to='/auth/forgot-password' className='link-primary'>
          Esqueceu a senha?
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-danger active'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continuar</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Por favor, aguarde...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
