import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty} from '../../../../../../_metronic/helpers'
import { Guardians} from '../core/_models'
import clsx from 'clsx'
import {toast} from 'react-toastify'
import {useListView} from '../core/ListViewProvider'
import {ListLoading} from '../components/loading/ListLoading'
import {updateGuardians} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import MaskedInput from 'react-input-mask'

type Props = {
  isAuthorsLoading: boolean
  response: any
  status: any
}

const editAuthorsSchema = Yup.object().shape({
  
})

const EditModalForm: FC<Props> = ({response, isAuthorsLoading}) => {
  const authors = response.length > 0 ? response[0] : response
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()

  const [authorsForEdit] = useState<Guardians>({
    ...authors,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: authorsForEdit,
    validationSchema: editAuthorsSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateGuardians(values)
          toast.success(`Autor ${values.name} atualizada com sucesso`)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y ps-1'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Nome</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Nome'
              {...formik.getFieldProps('name')}
              type='text'
              name='name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.name && formik.errors.name},
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isAuthorsLoading}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Endereço</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Endereço'
              {...formik.getFieldProps('address')}
              type='text'
              name='address'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.address && formik.errors.address},
                {
                  'is-valid': formik.touched.address && !formik.errors.address,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isAuthorsLoading}
            />
            {formik.touched.address && formik.errors.address && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.address}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>

          {/* begin::Input group */}
          <div className='row mb-7 m-0 p-0'>
            <div className='col-sm-5 ps-0'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>CEP</label>
              {/* end::Label */}

              {/* begin::Input */}
              <MaskedInput
                mask={"99.999-999"}
                placeholder='CEP'
                {...formik.getFieldProps('zip_code')}
                className='form-control form-control-solid mb-3 mb-lg-0'
                name='zip_code'
                autoComplete='off'
                disabled={formik.isSubmitting || isAuthorsLoading}
              />
              {formik.touched.zip_code && formik.errors.zip_code && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.zip_code}</span>
                  </div>
                </div>
              )}
              {/* end::Input */}
            </div>
            <div className='col-sm-2 ps-0'>
              <label className='fw-bold fs-6 mb-2'>Estado</label>
              <select
                className='form-control form-control-solid form-select mb-3 mb-lg-0'
                {...formik.getFieldProps('state_id')}
                name='state_id'
                disabled={formik.isSubmitting || isAuthorsLoading}
              >
                <option value=''></option>
                <option value={"1"}>AC</option>
                <option value={"2"}>AM</option>
                <option value={"3"}>RR</option>
                <option value={"4"}>TO</option>
                <option value={"5"}>RN</option>
                <option value={"6"}>PE</option>
                <option value={"7"}>AL</option>
                <option value={"8"}>SE</option>
                <option value={"9"}>BA</option>
                <option value={"10"}>MG</option>
                <option value={"11"}>RJ</option>
                <option value={"12"}>SC</option>
                <option value={"13"}>RS</option>
                <option value={"14"}>MS</option>
                <option value={"15"}>MT</option>
                <option value={"16"}>DF</option>
                <option value={"17"}>RO</option>
                <option value={"18"}>PA</option>
                <option value={"19"}>AP</option>
                <option value={"20"}>MA</option>
                <option value={"21"}>PI</option>
                <option value={"22"}>CE</option>
                <option value={"23"}>PB</option>
                <option value={"24"}>ES</option>
                <option value={"25"}>SP</option>
                <option value={"26"}>PR</option>
                <option value={"27"}>GO</option>
              </select>
              {formik.touched.state_id && formik.errors.state_id && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.state_id}</span>
                </div>
              )}
            </div>
            <div className='col-sm-5 ps-0'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Cidade</label>
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder='Cidade'
                {...formik.getFieldProps('city_name')}
                type='text'
                name='city_name'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.city_name && formik.errors.city_name},
                  {
                    'is-valid': formik.touched.city_name && !formik.errors.city_name,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isAuthorsLoading}
              />
              {formik.touched.city_name && formik.errors.city_name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.city_name}</span>
                  </div>
                </div>
              )}
              {/* end::Input */}
            </div>
          </div>
          
          <div className='row mb-7 m-0 p-0'>
            <div className='col-sm-6 ps-0'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Bairro</label>
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder='Bairro'
                {...formik.getFieldProps('district')}
                type='text'
                name='district'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.district && formik.errors.district},
                  {
                    'is-valid': formik.touched.district && !formik.errors.district,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isAuthorsLoading}
              />
              {formik.touched.district && formik.errors.district && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.district}</span>
                  </div>
                </div>
              )}
              {/* end::Input */}
            </div>
            <div className='col-sm-5 ps-0'>
              <label className='fw-bold fs-6 mb-2'>Status</label>
              <select
                className='form-control form-control-solid form-select mb-3 mb-lg-0'
                {...formik.getFieldProps('status_id')}
                name='status_id'
                disabled={formik.isSubmitting || isAuthorsLoading}
              >
                <option value=''></option>
               <option value={'9'}>Ativo</option>
               <option value={'10'}>Inativo</option>
              </select>
              {formik.touched.status_id && formik.errors.status_id && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.status_id}</span>
                </div>
              )}
            </div>
          </div>

          <div className='row mb-7 m-0 p-0'>
            <div className='col-sm-6 ps-0'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Celular</label>
              {/* end::Label */}

              {/* begin::Input */}
              <MaskedInput
                mask={"(99) 99999-9999"}
                placeholder='Celular'
                {...formik.getFieldProps('phone_number')}
                className='form-control form-control-solid mb-3 mb-lg-0'
                name='phone_number'
                autoComplete='off'
                disabled={formik.isSubmitting || isAuthorsLoading}
              />
              {formik.touched.phone_number && formik.errors.phone_number && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.phone_number}</span>
                  </div>
                </div>
              )}
              {/* end::Input */}
            </div>
            <div className='col-sm-5 ps-0'>
              <label className='fw-bold fs-6 mb-2'>Data de Nascimento</label>
              <MaskedInput
                mask={"99/99/9999"}
                placeholder='Data de Nascimento'
                {...formik.getFieldProps('birth_date')}
                className='form-control form-control-solid mb-3 mb-lg-0'
                name='birth_date'
                autoComplete='off'
                disabled={formik.isSubmitting || isAuthorsLoading}
              />
              {formik.touched.birth_date && formik.errors.birth_date && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.birth_date}</span>
                </div>
              )}
            </div>
          </div>
              
        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isAuthorsLoading}
          >
            Cancelar
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isAuthorsLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Confirmar</span>
            {(formik.isSubmitting || isAuthorsLoading) && (
              <span className='indicator-progress'>
                Por favor, aguarde...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isAuthorsLoading) && <ListLoading />}
    </>
  )
}

export {EditModalForm}
