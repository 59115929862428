import { useQueryRequest } from '../../core/QueryRequestProvider'
import { Guardians } from '../../core/_models'
import { getFilter } from '../../core/_requests'
import exportFromJSON from 'export-from-json' 
import {GuardiansListFilter} from './GuardiansListFilter'

type PropTypes = {
  showAddAuthors?: boolean
}


const GuardiansListToolbar = ({showAddAuthors = true}: PropTypes) => {
  const {state, searchTerm} = useQueryRequest();

  const exportAllVideosXLS = async () => {
    const otherFilters = (state.filter || {}) as Object
    const res = await getFilter({take:0, skip: 100000, name: searchTerm, ...otherFilters});
    const data: Guardians[] = (res.data) ? res.data : []
    const fileName = 'getinOxy-xls-videos';
    const exportType = 'xls';
    exportFromJSON({ data, fileName, exportType });
  }


  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <GuardiansListFilter />
      <div className="btn-group">
        <button type="button" className="btn btn-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
          <i className="ki-duotone ki-exit-down fs-2"><span className="path1"></span><span className="path2"></span></i>
          Exportar
        </button>
        <div id="kt_datatable_example_export_menu" className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-200px py-4" data-kt-menu="true">
          <div className="menu-item px-3">
            <a href="#/" className="menu-link px-3" data-kt-export="copy" onClick={exportAllVideosXLS} >
            Vídeos
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export {GuardiansListToolbar}
