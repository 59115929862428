import {useListView} from '../../core/ListViewProvider'
import {GuardiansListToolbar} from './GuardiansListToolbar'
import {GuardiansListGrouping} from './GuardiansListGrouping'
import {GuardiansSearchComponent} from './GuardiansSearchComponent'

type PropTypes = {
  showAddAuthors?: boolean
}

const GuardiansListHeader = ({showAddAuthors = true}: PropTypes) => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <GuardiansSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? (
          <GuardiansListGrouping />
        ) : (
          <GuardiansListToolbar showAddAuthors={showAddAuthors} />
        )}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {GuardiansListHeader}
