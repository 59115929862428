import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../../_metronic/helpers'
import {
  Assessment,
  AssessmentsQueryResponse,
} from './_models'

const API_URL = process.env.REACT_APP_API_URL

const getAssessmentsFilter = (query: any): Promise<AssessmentsQueryResponse> => {
  return axios
    .get(`${API_URL}/admin/assessments/filter`, {params: query})
    .then((d: AxiosResponse<AssessmentsQueryResponse>) => d.data)
}

const createAssessments = (caegory: Assessment): Promise<Assessment | undefined> => {
  return axios
    .post(`${API_URL}/admin/assessments/create`, caegory)
    .then((response: AxiosResponse<Response<Assessment>>) => response.data)
    .then((response: Response<Assessment>) => response.data)
}

const updateAssessments = (caegory: Assessment): Promise<Assessment | undefined> => {
  return axios
    .post(`${API_URL}/admin/assessments/update/${caegory.id}`, caegory)
    .then((response: AxiosResponse<Response<Assessment>>) => response.data)
    .then((response: Response<Assessment>) => response.data)
}

export {
  getAssessmentsFilter,
  createAssessments,
  updateAssessments
}
