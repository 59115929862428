// @ts-nocheck
import {Column} from 'react-table'
import {CategoryInfoCell} from './CategoryInfoCell'
import {CategoryStatusCell} from './CategoryStatusCell'
// import {UserSelectionCell} from './UserSelectionCell'
import {CategoryCustomHeader} from './CategoryCustomHeader'
// import {UserSelectionHeader} from './UserSelectionHeader'
import {Category} from '../../core/_models'
import { CategoryTypeCell } from './CategoryTypeCell'
import { CategoryIdCell } from './CategoryIdCell'
import { CategoryActionsCell } from './CategoryActionsCell'
import { CategoryGenericCell } from './CategoryGenericCell'

const categoriesColumns: ReadonlyArray<Column<Category>> = [
  // {
  //   Header: (props) => <UserSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => (
      <CategoryCustomHeader tableProps={props} title='Id' className='min-w-25px' />
    ),
    id: 'id',
    Cell: ({...props}) => <CategoryIdCell category={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CategoryCustomHeader tableProps={props} title='Categoria' className='min-w-125px' />
    ),
    id: 'name',
    Cell: ({...props}) => <CategoryInfoCell category={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CategoryCustomHeader tableProps={props} title='Status' className='min-w-90px' />
    ),
    id: 'status_name',
    Cell: ({...props}) => <CategoryStatusCell category={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CategoryCustomHeader tableProps={props} title='Tipo' className='min-w-90px' />
    ),
    id: 'type',
    Cell: ({...props}) => <CategoryTypeCell category={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CategoryCustomHeader tableProps={props} title='Imagem' className='min-w-90px' />
    ),
    id: 'image_thumb',
    Cell: ({...props}) => <CategoryGenericCell labelName={props.data[props.row.index].image_thumb} />,
  },
  {
    Header: (props) => (
      <CategoryCustomHeader tableProps={props} title='Ordem' className='min-w-20px' />
    ),
    id: 'position',
    Cell: ({...props}) => <CategoryGenericCell labelName={props.data[props.row.index].position} />,
  },
  {
    Header: (props) => (
      <CategoryCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <CategoryActionsCell
        id={props.data[props.row.index].id}
      />
    ),
  },
]

export {categoriesColumns}
