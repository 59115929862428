import {useListView} from '../../core/ListViewProvider'
import {MeetingsListToolbar} from './MeetingsListToolbar'
import {MeetingsListGrouping} from './MeetingsListGrouping'
import {MeetingsSearchComponent} from './MeetingsSearchComponent'

type PropTypes = {
  showAddMeetings?: boolean
}

const MeetingsListHeader = ({showAddMeetings = true}: PropTypes) => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <MeetingsSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? (
          <MeetingsListGrouping />
        ) : (
          <MeetingsListToolbar showAddMeetings={showAddMeetings} />
        )}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {MeetingsListHeader}
