import {FC, useState} from 'react'
import MaskedInput from 'react-input-mask'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty} from '../../../../../../_metronic/helpers'
import {Assessment} from '../core/_models'
import clsx from 'clsx'
import {toast} from 'react-toastify'
import {useListView} from '../core/ListViewProvider'
import {AssessmentsListLoading} from '../components/loading/AssessmentsListLoading'
import {createAssessments, updateAssessments} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'

type Props = {
  isAssessmentLoading: boolean
  response: any
  status: any
}

const editassessmentsSchema = Yup.object().shape({
  name: Yup.string()
    .min(1, 'Mínimo 3 caracteres')
    .max(10, 'Maximum 50 caracteres')
    .required('Nome é obrigatório')
})

const AssessmentsEditModalForm: FC<Props> = ({response, isAssessmentLoading}) => {
  const assessments = response.length > 0 ? response[0] : response
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()

  const [assessmentsForEdit] = useState<Assessment>({
    ...assessments,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: assessmentsForEdit,
    validationSchema: editassessmentsSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateAssessments(values)
          toast.success(`Teste ${values.name} atualizado com sucesso`)
        } else {
          await createAssessments(values)
          toast.success(`Teste ${values.name} criado com sucesso`)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y ps-1'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
        <div className='row mb-7 m-0 p-0'>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Nome</label>
            <input
              type="text"
              placeholder='Nome do Teste'
              {...formik.getFieldProps('name')}              
              name='name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.name && formik.errors.name},
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isAssessmentLoading}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Descrição</label>
            <input
              type="text"
              placeholder='Descrição do Teste'
              {...formik.getFieldProps('description')}              
              name='description'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.description && formik.errors.description},
                {
                  'is-valid': formik.touched.description && !formik.errors.description,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isAssessmentLoading}
            />
            {formik.touched.description && formik.errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.description}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Número de perguntas</label>
            <MaskedInput
              mask={"999"}
              maskChar={null}
              type="text"
              placeholder='Número de perguntas'
              {...formik.getFieldProps('questions_number')}              
              name='questions_number'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.questions_number && formik.errors.questions_number},
                {
                  'is-valid': formik.touched.questions_number && !formik.errors.questions_number,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isAssessmentLoading}
            />
            {formik.touched.questions_number && formik.errors.questions_number && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.questions_number}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Tempo para concluir o teste (em segundos)</label>
            <MaskedInput
              mask={"999999"}
              maskChar={null}
              type="text"
              placeholder='Tempo para concluir o teste (em segundos)'
              {...formik.getFieldProps('time_to_finish')}              
              name='time_to_finish'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.time_to_finish && formik.errors.time_to_finish},
                {
                  'is-valid': formik.touched.time_to_finish && !formik.errors.time_to_finish,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isAssessmentLoading}
            />
            {formik.touched.time_to_finish && formik.errors.time_to_finish && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.time_to_finish}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Total de tentativas</label>
            <MaskedInput
              mask={"999"}
              maskChar={null}
              type="text"
              placeholder='Total de tentativas'
              {...formik.getFieldProps('attempts_limit')}              
              name='attempts_limit'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.attempts_limit && formik.errors.attempts_limit},
                {
                  'is-valid': formik.touched.attempts_limit && !formik.errors.attempts_limit,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isAssessmentLoading}
            />
            {formik.touched.attempts_limit && formik.errors.attempts_limit && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.attempts_limit}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Pontuação para aprovação</label>
            <MaskedInput
              mask={"999"}
              maskChar={null}
              type="text"
              placeholder='Pontuação mínima para aprovação no teste'
              {...formik.getFieldProps('required_score')}              
              name='required_score'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.required_score && formik.errors.required_score},
                {
                  'is-valid': formik.touched.required_score && !formik.errors.required_score,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isAssessmentLoading}
            />
            {formik.touched.required_score && formik.errors.required_score && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.required_score}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Tempo de intervalo entre as tentativas</label>
            <MaskedInput
              mask={"9999999"}
              maskChar={null}
              type="text"
              placeholder='Tempo de intervalo entre as tentativas'
              {...formik.getFieldProps('attempt_interval')}              
              name='attempt_interval'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.attempt_interval && formik.errors.attempt_interval},
                {
                  'is-valid': formik.touched.attempt_interval && !formik.errors.attempt_interval,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isAssessmentLoading}
            />
            {formik.touched.attempt_interval && formik.errors.attempt_interval && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.attempt_interval}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-sm-5 ps-0'>
              <label className='fw-bold fs-6 mb-2'>Status</label>
              <select
                className='form-control form-control-solid form-select mb-3 mb-lg-0'
                {...formik.getFieldProps('status_id')}
                name='status_id'
                disabled={formik.isSubmitting || isAssessmentLoading}
              >
                <option disabled>
                  Selecione o status
                </option>
               <option value={'23'}>Ativo</option>
               <option value={'24'}>Inativo</option>
              </select>
              {formik.touched.status_id && formik.errors.status_id && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.status_id}</span>
                </div>
              )}
          </div>
          <div className='col-sm-5 ps-0'>
              <label className='fw-bold fs-6 mb-2'>Tipo</label>
              <select
                className='form-control form-control-solid form-select mb-3 mb-lg-0'
                {...formik.getFieldProps('type_id')}
                name='type_id'
                disabled={formik.isSubmitting || isAssessmentLoading}
              >
                <option disabled>
                  Selecione o status
                </option>
               <option value={'1'}>Sim/Não</option>
              </select>
              {formik.touched.type_id && formik.errors.type_id && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.type_id}</span>
                </div>
              )}
          </div>
        </div>
        </div>
        {/* end::Scroll */}
              
        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isAssessmentLoading}
          >
            Cancelar
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isAssessmentLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Confirmar</span>
            {(formik.isSubmitting || isAssessmentLoading) && (
              <span className='indicator-progress'>
                Por favor, aguarde...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isAssessmentLoading) && <AssessmentsListLoading />}
    </>
  )
}

export {AssessmentsEditModalForm}
