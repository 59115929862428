// @ts-nocheck
import {Column} from 'react-table'
import {CustomHeader} from './CustomHeader'
import {Authors} from '../../core/_models'
import { GenericCell } from './GenericCell'
import { InfoCell } from './InfoCell'

const categoriesColumns: ReadonlyArray<Column<Authors>> = [
  // {
  //   Header: (props) => <UserSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Id' className='min-w-25px' />
    ),
    id: 'id',
    Cell: ({...props}) => <GenericCell labelName={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Vídeo' className='min-w-125px' />
    ),
    id: 'video_name',
    Cell: ({...props}) => <InfoCell guardians={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='ID.Criança' className='min-w-90px' />
    ),
    id: 'children_id',
    Cell: ({...props}) => <GenericCell labelName={props.data[props.row.index].children_id} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Criança' className='min-w-90px' />
    ),
    id: 'children_name',
    Cell: ({...props}) => <GenericCell labelName={props.data[props.row.index].children_name} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='ID.Responsável' className='min-w-90px' />
    ),
    id: 'guardian_id',
    Cell: ({...props}) => <GenericCell labelName={props.data[props.row.index].guardian_id} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Responsável' className='min-w-90px' />
    ),
    id: 'guardian_name',
    Cell: ({...props}) => <GenericCell labelName={props.data[props.row.index].guardian_name} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Data Envio' className='min-w-90px' />
    ),
    id: 'created_at',
    Cell: ({...props}) => <GenericCell labelName={props.data[props.row.index].created_at} />,
  }
]

export {categoriesColumns}
