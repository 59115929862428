import { useQuery } from "react-query"
import { GETCardUsersData } from "../core/_requests"

type Props = {
  className: string
  description: string
  color: string
  img: string
}

const UsersCard = ({ className, description, color, img }: Props) => {

  const { data, isLoading } = useQuery(
    'users-card-report',
    GETCardUsersData, {
    cacheTime: 0,
    refetchOnWindowFocus: false
  })

  return (
    <div
      className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end ${className}`}
      style={{
        backgroundColor: color,
        backgroundImage: `url('${img}')`,
        height: '100%'
      }}
    >
      <div className='card-header pt-5' style={{ gap: 20 }}>
        <div className='card-title d-flex flex-column'>
          {isLoading ? (
            <>
              <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2 bg-white rounded animation-breathe'>00</span>
              <span className='text-white mt-3 fw-semibold fs-6 bg-white rounded animation-breathe'>{description}</span>
            </>
          ) : (
            <>
              <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'>{data?.total_videos}</span>
              <span className='text-white opacity-75 pt-1 fw-semibold fs-6'>{description}</span>
            </>
          )}
        </div>
        <div className='card-title d-flex flex-column' style={{ marginRight: 'auto' }}>
          {isLoading ? (
            <>
              <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2 bg-white rounded animation-breathe'>00</span>
              <span className='text-white mt-3 fw-semibold fs-6 bg-white rounded animation-breathe'>Crianças totais</span>
            </>
          ) : (
            <>
              <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'>{data?.total_childrens}</span>
              <span className='text-white opacity-75 pt-1 fw-semibold fs-6'>Crianças totais</span>
            </>
          )}
        </div>
        <div className='card-title d-flex flex-column' style={{ marginRight: 'auto' }}>
          {isLoading ? (
            <>
              <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2 bg-white rounded animation-breathe'>00</span>
              <span className='text-white mt-3 fw-semibold fs-6 bg-white rounded animation-breathe'>Responsáveis totais</span>
            </>
          ) : (
            <>
              <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'>{data?.total_guardians}</span>
              <span className='text-white opacity-75 pt-1 fw-semibold fs-6'>Responsáveis totais</span>
            </>
          )}
        </div>
        <div className='card-title d-flex flex-column' style={{ marginRight: 'auto' }}>
          {isLoading ? (
            <>
              <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2 bg-white rounded animation-breathe'>00</span>
              <span className='text-white mt-3 fw-semibold fs-6 bg-white rounded animation-breathe'>Doenças Cadastradas</span>
            </>
          ) : (
            <>
              <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'>{data?.total_disease}</span>
              <span className='text-white opacity-75 pt-1 fw-semibold fs-6'>Doenças Cadastradas</span>
            </>
          )}
        </div>
        <div className='card-title d-flex flex-column' style={{ marginRight: 'auto' }}>
          {isLoading ? (
            <>
              <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2 bg-white rounded animation-breathe'>00</span>
              <span className='text-white mt-3 fw-semibold fs-6 bg-white rounded animation-breathe'>Usos Registrados</span>
            </>
          ) : (
            <>
              <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'>{data?.total_medical_history}</span>
              <span className='text-white opacity-75 pt-1 fw-semibold fs-6'>Usos Registrados</span>
            </>
          )}
        </div>
      </div>
    </div >
  )
}
export { UsersCard }
