const QUERIES = {
  USERS_LIST: 'users-list',
  CATEGORIES_LIST: 'categories-list',
  LESSONS_LIST: 'lessons-list',
  INDICATORS_LIST: 'financial-indicators-list',
  MEETINGS_LIST: 'meetings-list',
  DOCUMENTS_LIST: 'documents-list',
  STATES: 'states',
  CITIES: 'cities',
  COMPANIES: 'companies',
  COURSES_LIST: 'courses-list',
  GUARDIANS_LIST: 'guardians-list',
  MEDICAL_HISTORIES_LIST: 'medical-histories-list',
  AREAS_LIST: 'areas-list',
  ASSESSMENTS_LIST: 'assessments-list',
  QUESTIONS_LIST: 'questions-list',
}

export {QUERIES}
