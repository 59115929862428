import {FC} from 'react'
import {Assessment, AssessmentStatus} from '../../core/_models'

type Props = {
  category: Assessment
}

const AssessmentsStatusCell: FC<Props> = ({category}) => {
  let look: string = 'light-primary'
  switch (category.status_name) {
    case AssessmentStatus.active.value:
      look = 'light-success'
      break
    case AssessmentStatus.inactive.value:
      look = 'light-secondary text-dark'
      break
  }

  return (
    <>
      {category && (
        <div className={`badge badge-${look} fw-bolder`}>
          {category.status_name.charAt(0).toUpperCase() + category.status_name.slice(1)}
        </div>
      )}
    </>
  )
}

export {AssessmentsStatusCell}
