import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {CategoriesListWrapper} from './categories-list/CategoriesList'

const categoriesBreadcrumbs: Array<PageLink> = [
  {
    title: 'Gerenciamento de Categorias',
    path: '/apps/categories-management/categories',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const CategoriesPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='categories'
          element={
            <>
              <PageTitle breadcrumbs={categoriesBreadcrumbs}>Lista de Categorias</PageTitle>
              <CategoriesListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/categories-management/categories' />} />
    </Routes>
  )
}

export default CategoriesPage
