import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {AssessmentsListFilter} from './AssessmentsListFilter'
import {getAssessmentsFilter} from '../../core/_requests'
import exportFromJSON from 'export-from-json' 
import { Assessment } from '../../core/_models'
import {useQueryRequest} from '../../core/QueryRequestProvider'


type PropTypes = {
  showAddassessments?: boolean
}

const AssessmentsListToolbar = ({showAddassessments = true}: PropTypes) => {
  const {state, searchTerm} = useQueryRequest()
  const {setItemIdForUpdate} = useListView()
  const openAddCategoryModal = () => {
    setItemIdForUpdate(null)
  }

  const exportXLS = async () => {
    const otherFilters = (state.filter || {}) as Object
    const res = await getAssessmentsFilter({take:0, skip: 100000, name: searchTerm, ...otherFilters});
    const data: Assessment[] = (res.data) ? res.data : []
    const fileName = 'getinOxy-xls-assessments';
    const exportType = 'xls';

    exportFromJSON({ data, fileName, exportType });
    
  }
 
  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <AssessmentsListFilter />
      {showAddassessments && (
        <button type='button' className='btn btn-primary me-3' onClick={openAddCategoryModal}>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          Adicionar Novo Teste
        </button>
      )}
			<button type="button" onClick={exportXLS} className="btn btn-light-primary" data-kt-menu-trigger="click" >
				<i className="ki-duotone ki-exit-down fs-2"><span className="path1"></span><span className="path2"></span></i>
				Exportar
			</button>
    </div>
  )
}

export {AssessmentsListToolbar}
