import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {QuestionsListHeader} from './components/header/QuestionsListHeader'
import {QuestionsTable} from './table/QuestionsTable'
import {QuestionsEditModal} from './questions-edit-modal/QuestionsEditModal'
import {KTCard} from '../../../../../_metronic/helpers'

type PropTypes = {
  /** Shows the [Add User] button inside the header on the top right corner */
  showAddquestions?: boolean
  /** An array of columns that you don't want shown in the user list */
  hiddenColumns?: string[]
}

const QuestionsList = ({showAddquestions = true, hiddenColumns = []}: PropTypes) => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <QuestionsListHeader showAddquestions={showAddquestions} />
        <QuestionsTable hiddenColumns={hiddenColumns} />
      </KTCard>
      {itemIdForUpdate !== undefined && <QuestionsEditModal />}
    </>
  )
}

const QuestionsListWrapper = ({showAddquestions = true, hiddenColumns = []}: PropTypes) => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <QuestionsList showAddquestions={showAddquestions} hiddenColumns={hiddenColumns} />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {QuestionsListWrapper}
