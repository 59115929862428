import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {CategoriesListFilter} from './CategoriesListFilter'

type PropTypes = {
  showAddCategory?: boolean
}

const CategoriesListToolbar = ({showAddCategory = true}: PropTypes) => {
  const {setItemIdForUpdate} = useListView()
  const openAddCategoryModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <CategoriesListFilter />
      {/* begin::Add category */}
      {showAddCategory && (
        <button type='button' className='btn btn-primary' onClick={openAddCategoryModal}>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          Adicionar Categoria
        </button>
      )}
      {/* end::Add category */}
    </div>
  )
}

export {CategoriesListToolbar}
