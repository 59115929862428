/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../../../_metronic/helpers'
import {getFilter} from './_requests'
import {Guardians, GuardiansQueryResponse} from './_models'
import {useQueryRequest} from './QueryRequestProvider'

const QueryResponseContext = createResponseContext<Guardians>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state, searchTerm} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.GUARDIANS_LIST}-${query}`,
    async () => {
      const otherFilters = (state.filter || {}) as Object
      let searchParams = new URLSearchParams(query)
      const take = parseInt(searchParams.get('page') || '1') - 1
      const skip = searchParams.get('items_per_page')
      if (!state.sort) {
        const res = await getFilter({take, skip, name: searchTerm, ...otherFilters});
        return res;
      } else {
        const response: GuardiansQueryResponse = await getFilter({
          take, skip,
          name: searchTerm,
          ...otherFilters,
        })
        const meetings = response?.data as Guardians[]

        let meetingsSorted: Guardians[]
        if (meetings.length > 1) {
          const key = state.sort as keyof Guardians
          meetingsSorted = meetings!.sort((a, b) => {
            if (state.order === 'asc')
              if (typeof a[key] !== 'boolean')
                return (a[key]?.toString() as string).localeCompare(b[key]?.toString() as string)
              else return (b[key]?.toString() as string).localeCompare(a[key]?.toString() as string)
            if (typeof a[key] !== 'boolean')
              return (b[key]?.toString() as string).localeCompare(a[key]?.toString() as string)
            else return (a[key]?.toString() as string).localeCompare(b[key]?.toString() as string)
          })
        } else {
          meetingsSorted = meetings
        }

        return meetingsSorted as GuardiansQueryResponse
      }
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()
  if (!response) {
    return []
  }

  return (response?.data as Guardians[]) || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useQueryResponse()
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }

  return response.payload.pagination
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
