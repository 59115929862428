import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {CategoriesListHeader} from './components/header/CategoriesListHeader'
import {CategoriesTable} from './table/CategoriesTable'
import {CategoryEditModal} from './category-edit-modal/CategoryEditModal'
import {KTCard} from '../../../../../_metronic/helpers'

type PropTypes = {
  /** Shows the [Add User] button inside the header on the top right corner */
  showAddCategory?: boolean
  /** An array of columns that you don't want shown in the user list */
  hiddenColumns?: string[]
}

const CategoriesList = ({showAddCategory = true, hiddenColumns = []}: PropTypes) => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <CategoriesListHeader showAddCategory={showAddCategory} />
        <CategoriesTable hiddenColumns={hiddenColumns} />
      </KTCard>
      {itemIdForUpdate !== undefined && <CategoryEditModal />}
    </>
  )
}

const CategoriesListWrapper = ({showAddCategory = true, hiddenColumns = []}: PropTypes) => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <CategoriesList showAddCategory={showAddCategory} hiddenColumns={hiddenColumns} />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {CategoriesListWrapper}
