import {useQuery} from 'react-query'
import {AssessmentsEditModalForm} from './AssessmentsEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getAssessmentsFilter} from '../core/_requests'
import { AssessmentType } from '../core/_models'

const AssessmentsEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)

  const {
    isLoading,
    data: assessments,
    error,
    status
  } = useQuery(
    `${QUERIES.ASSESSMENTS_LIST}-assessments-${itemIdForUpdate}`,
    () => {
      return getAssessmentsFilter({id: itemIdForUpdate, take: 0, skip: 1})
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return (
      <AssessmentsEditModalForm
      isAssessmentLoading={isLoading}
        response={{
          name: AssessmentType['Sim/Não'].id,
          created_at: new Date(),
        }}
        status={status}
      />
    )
  }

  if (!isLoading && !error && assessments) {
    return (
      <AssessmentsEditModalForm
      isAssessmentLoading={isLoading}
        response={assessments?.data}
        status={status}
      />
    )
  }

  return null
}

export {AssessmentsEditModalFormWrapper}
