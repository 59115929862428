import {FC} from 'react'

type Props = {
  labelName: string
}

const DocumentsGenericCell: FC<Props> = ({labelName}) => {
  return (
    <>
      {
        <div className='d-flex align-items-center'>
        {/* begin:: Avatar */}
        <div className='d-flex flex-column'>
            {labelName}
        </div>
      </div>
      }
    </>
  )
}

export {DocumentsGenericCell}
