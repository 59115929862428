import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {DocumentsListFilter} from './DocumentsListFilter'

type PropTypes = {
  showAddDocuments?: boolean
}

const DocumentsListToolbar = ({showAddDocuments = true}: PropTypes) => {
  const {setItemIdForUpdate} = useListView()
  const openAddMeetingsModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <DocumentsListFilter />
      {/* begin::Add category */}
      {showAddDocuments && (
        <button type='button' className='btn btn-primary' onClick={openAddMeetingsModal}>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          Adicionar Notícia
        </button>
      )}
      {/* end::Add category */}
    </div>
  )
}

export {DocumentsListToolbar}
