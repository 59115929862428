import {ID, Response} from '../../../../../../_metronic/helpers'

export const MeetingsStatus = {
  'active': {id: 34, value: 'active', name: 'Ativo'},
  'inactive': {id: 35, value: 'inactive', name: 'Inativo'},
  'canceled': {id: 36, value: 'canceled', name: 'Cancelado'},
}

export type Meetings = {
  id?: ID
  title:string
  description:string
  url:string
  status_id:number
  status_name:string
  type_id:number
  type_name:string
  scheduled_date?:Date
  days_before:number
  days_after:number
  meeting_id:string
  image_thumb:string
  image_url:string
}

export type Status = {
  id?: ID
  name?: string
}


export type MeetingsQueryResponse = Response<Array<Meetings>>

export const initialMeetings: Meetings = {
  title: '',
  status_name: MeetingsStatus.active.name,
  status_id: 34,
  description: '',
  image_thumb: '',
  image_url: '',
  type_id: 8,
  url: '',
  type_name: 'Zoom',
  days_before: 0,
  days_after: 0,
  meeting_id: '',
}

export type ImageUploadResponse = Response<{file_name: string}>
